/* ==========  Libs  ========== */
import 'bootstrap/js/dist/util';

// import 'bootstrap/js/dist/alert'

// import 'bootstrap/js/dist/button'

// import 'bootstrap/js/dist/carousel'

// import 'bootstrap/js/dist/collapse';

import 'bootstrap/js/dist/dropdown';

import 'bootstrap/js/dist/modal';

// import 'bootstrap/js/dist/scrollspy'

// import 'bootstrap/js/dist/tab';

// import 'bootstrap/js/dist/tooltip.js'
// import 'bootstrap/js/dist/popover.js'
// import 'popper.js/dist/popper.js'

import 'slick-carousel/slick/slick.js';

import 'bootstrap-select/js/bootstrap-select';

import 'jquery.maskedinput/src/jquery.maskedinput';

import 'rangeslider.js';

import 'intersection-observer';

import sal from 'sal.js';

import TweenMax from 'gsap/TweenMax';

// import 'bootstrap-slider';

// import 'jquery-parallax.js/parallax';

// import AOS from 'aos/dist/aos';

import * as basicScroll from 'basicscroll';

document.querySelectorAll('.scene').forEach(elem => {
  const modifier = elem.getAttribute('data-modifier');

  basicScroll
    .create({
      elem: elem,
      from: 0,
      to: 1000,
      direct: true,
      props: {
        '--translateY': {
          from: '0',
          to: `${10 * modifier}px`,
        },
      },
    })
    .start();
});

sal({
  threshold: 0.1,
  once: false,
});
/* ==========  Custom scripts  ========== */

// Иницилизация AOS
// AOS.init();

$(document).ready(() => {
  $(() => {
    // Функция прокрутки
    $(window).scroll(() => {
      if (window.page === 'index') {
        const windowTop = $(window).scrollTop();
        windowTop > 0
          ? $('.main-header__menu').addClass('main-header__menu--show')
          : $('.main-header__menu').removeClass('main-header__menu--show');
      }
    });
  });

  $('a[href*="#link-"]').on('click', function(e) {
    $('html,body').animate(
      {
        scrollTop: $($(this).attr('href')).offset().top - 67,
      },
      400
    );
    e.preventDefault();
  });

  // Кнопка меню
  $('.menu-toggle').on('click', function() {
    $(this).toggleClass('menu-toggle--show');
    $('.nav-right').toggleClass('main-header__menu--show');
    $('.page').toggleClass('no-scroll--js');
  });

  $('.btn-phone, .btn-green--menu').on('click', function() {
    $('.nav-right').removeClass('main-header__menu--show');
    $('.menu-toggle').removeClass('menu-toggle--show');
    $('.page').removeClass('no-scroll--js');
  });

  if (window.page !== 'index') {
    $('.main-header__menu').addClass('main-header__menu--white');
    $('.main-header__logo-text').css('color', 'black');
    $('.main-menu__items > li > a').addClass('main-menu__item--color');
    $('.phone-number').addClass('main-menu__item--color');
    $('.menu-icon__bar').css('background', 'black');
  }

  // smooth scroll to top
  $('.footer-top').on('click', function(event) {
    event.preventDefault();
    $('body,html').animate(
      {
        scrollTop: 0,
      },
      600
    );
  });

  // Toggle Menu
  // $('#menu-toggle').on('click', () => {
  //   $('#menu-toggle').toggleClass('closeMenu');
  //   $('ul').toggleClass('showMenu');

  //   // $('li').on('click', () => {
  //   //   $('ul').removeClass('showMenu');
  //   //   $('#menu-toggle').removeClass('closeMenu');
  //   // });
  // });

  // $('.main-calc__order').click(function() {
  //   $('.main-calc__modal').addClass('main-calc__modal--show');
  // });

  // Маска телефона
  $('#phone').mask('+7 (999) 999-99-99');
  $('#phone-2').mask('+7 (999) 999-99-99');
  $('#phone-3').mask('+7 (999) 999-99-99');
  $('#phone-4').mask('+7 (999) 999-99-99');

  // function mediaScroll() {
  //   if ($(window).width() < 1200) {
  //   } else {
  //     $(window).scroll(() => {
  //       let windowTop = $(window).scrollTop();
  //       windowTop > 100
  //         ? $('.logo__text').hide('slow')
  //         : $('.logo__text').show('slow');
  //     });
  //   }
  // }

  // mediaScroll();

  // $(window).resize(function() {
  //   mediaScroll();
  // });

  // Политика конфиденциальности
  // $(function() {
  //   $('#checkboxPolitics-1').click(function() {
  //     let submit = document.getElementsByName('main-calc__submit')[0];
  //     if (document.getElementById('checkboxPolitics-1').checked)
  //       submit.disabled = '';
  //     else submit.disabled = 'disabled';
  //   });
  // });

  // $(function() {
  //   $('#checkboxPolitics-2').click(function() {
  //     let submit = document.getElementsByName('submit-application__submit')[0];
  //     if (document.getElementById('checkboxPolitics-2').checked)
  //       submit.disabled = '';
  //     else submit.disabled = 'disabled';
  //   });
  // });

  // $(function() {
  //   $('#checkboxPolitics-3').click(function() {
  //     let submit = document.getElementsByName('request-call__submit')[0];
  //     if (document.getElementById('checkboxPolitics-3').checked)
  //       submit.disabled = '';
  //     else submit.disabled = 'disabled';
  //   });
  // });

  // $(function() {
  //   $('#checkboxPolitics-4').click(function() {
  //     let submit = document.getElementsByName('request-call__submit--two')[0];
  //     if (document.getElementById('checkboxPolitics-4').checked)
  //       submit.disabled = '';
  //     else submit.disabled = 'disabled';
  //   });
  // });

  // Кнопка вверх
  // $('.main-footer__arrow-up').on('click', () => {
  //   $('html,body').animate(
  //     {
  //       scrollTop: 0,
  //     },
  //     500
  //   );
  // });

  const $rangeslider = $('#areaflatRange');
  const $amount = $('#areaflat');

  $rangeslider
    .rangeslider({
      polyfill: true,
    })
    .on('input', function() {
      $amount[0].value = this.value;
    });

  $amount.on('input', function() {
    $rangeslider.val(this.value).change();
  });

  $('#areaflatRange').on('change keyup mousemove touchmove', function() {
    $('#areaflatRange').val($(this).val());
    $('.range-slider')
      .on('#areaflatRange', function(e) {
        var min = e.target.min,
          max = e.target.max,
          val = e.target.value;
        $(e.target).css({
          backgroundSize: ((val - min) * 100) / (max - min) + '% 100%',
        });
      })
      .trigger('#areaflatRange');
  });

  // Калькулятор
  $('.main-calc').on('click input', function() {
    if ($('#superselect').val() == 0) {
      $('button[data-id=periodCalc]').removeAttr('disabled');
    } else {
      $('button[data-id=periodCalc]').attr('disabled', 'disabled');
    }

    let areaflat = $('#areaflat').val();
    let coef = 0;
    let arrayPriceCleaning = [
      [],
      [
        6000,
        6000,
        7000,
        7000,
        8000,
        8000,
        9000,
        9000,
        10000,
        10000,
        12250,
        13125,
        14000,
        14875,
      ],
      [
        5500,
        5500,
        6500,
        6500,
        7500,
        7500,
        8500,
        12750,
        12750,
        11050,
        11900,
        12750,
        13600,
      ],
      [
        1750,
        2190,
        2690,
        2890,
        3390,
        3690,
        4090,
        4590,
        4990,
        5290,
        5590,
        5990,
        6390,
        6790,
      ],
      [
        3000,
        3800,
        3800,
        4500,
        4500,
        5400,
        5400,
        6300,
        6300,
        6370,
        6860,
        7350,
        7840,
        8330,
      ],
      [
        675,
        690,
        690,
        915,
        915,
        1215,
        1215,
        2250,
        2250,
        1560,
        1680,
        1800,
        1920,
        2040,
      ],
      [
        1680,
        1860,
        2100,
        2280,
        2520,
        2640,
        2750,
        3030,
        3030,
        3580,
        3850,
        4130,
        4400,
        4680,
      ],
    ]; // [4200, 5250, 6300, 7350, 8400, 9450, 10500, 11550, 12600, 13650, 14700, 15750, 16800, 17850], // Офисы // Поддерживающая // Послеремонтная уборка // генеральная // окна // Разовая // На постоянной основе // 2 раза в неделю

    let additionalArray = [0, 100, 85, 40, 50, 15, 28];

    var typeclealing = 0;
    var typeclealmain = $('#superselect').val();
    var otherarea = 0;

    if (typeclealmain == 0) {
      typeclealing = $('option[id=paradio]:selected').val();
    } else {
      typeclealing = typeclealmain;
    }

    if (areaflat <= 45) {
      coef = 0;
    }
    if (areaflat > 45) {
      coef = 1;
    }
    if (areaflat > 46) {
      coef = 2;
    }
    if (areaflat > 60) {
      coef = 3;
    }
    if (areaflat > 61) {
      coef = 4;
    }
    if (areaflat > 80) {
      coef = 5;
    }
    if (areaflat > 81) {
      coef = 6;
    }
    if (areaflat > 100) {
      coef = 7;
    }
    if (areaflat > 101) {
      coef = 8;
    }
    if (areaflat > 200) {
      coef = 9;
    }
    if (areaflat > 201) {
      coef = 10;
    }
    if (areaflat > 400) {
      coef = 11;
    }

    var resultCleaning = arrayPriceCleaning[typeclealing][coef];
    (function() {
      /**
       * Корректировка округления десятичных дробей.
       *
       * @param {String}  type  Тип корректировки.
       * @param {Number}  value Число.
       * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
       * @returns {Number} Скорректированное значение.
       */
      function decimalAdjust(type, value, exp) {
        // Если степень не определена, либо равна нулю...
        if (typeof exp === 'undefined' || +exp === 0) {
          return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Если значение не является числом, либо степень не является целым числом...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
          return NaN;
        }
        // Сдвиг разрядов
        value = value.toString().split('e');
        value = Math[type](
          +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
        );
        // Обратный сдвиг
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
      }

      // Десятичное округление к ближайшему
      if (!Math.round10) {
        Math.round10 = function(value, exp) {
          return decimalAdjust('round', value, exp);
        };
      }
      // Десятичное округление вниз
      if (!Math.floor10) {
        Math.floor10 = function(value, exp) {
          return decimalAdjust('floor', value, exp);
        };
      }
      // Десятичное округление вверх
      if (!Math.ceil10) {
        Math.ceil10 = function(value, exp) {
          return decimalAdjust('ceil', value, exp);
        };
      }
    })();
    if (areaflat > 150) {
      otherarea = Math.ceil10((areaflat - 150) / 1);
      resultCleaning =
        arrayPriceCleaning[typeclealing][coef] +
        otherarea * additionalArray[typeclealing];
    }
    var typeCleanToHtml = [
      'Поддерживающая уборка',
      'Уборка после ремонта',
      'Генеральная уборка',
      'Мытье окон',
    ];
    $('#resultcalc').html(
      '<span class="main-calc__result--sum">' +
        resultCleaning.toLocaleString('ru') +
        '</span>' +
        ' Руб'
    );
    $('.textareacalc').html(
      'Цена: ' +
        resultCleaning +
        ', Площадь: ' +
        areaflat +
        ' м2' +
        ', Тип уборки: ' +
        typeCleanToHtml[typeclealmain]
    );
  });

  // Формы
  $('form').submit(function() {
    var formID = $(this).attr('id'); // Получение ID формы
    var formNm = $('#' + formID);
    $.ajax({
      type: 'POST',
      url: '/wp-content/themes/apartment-cleaning/inc/mail.php', // Обработчик формы отправки
      data: formNm.serialize(),
      success: function(data) {
        // Вывод текста результата отправки в текущей форме
        $(formNm).html(data);
        setTimeout(function() {
          $('.modal--hide').modal('hide');
        }, 3000);
      },
    });
    return false;
  });

  $('.cleaning-services__items').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    arrows: false,
    speed: 600,
    responsive: [
      {
        breakpoint: 90000,
        settings: 'unslick',
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $(window).on('resize orientationchange', function() {
    let mySlider = $('.cleaning-services__items');

    if (!mySlider.hasClass('slick-initialized')) {
      mySlider.slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        autoplay: false,
        arrows: false,
        speed: 600,
        responsive: [
          {
            breakpoint: 90000,
            settings: 'unslick',
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
  });

  $('.reviews__items').slick({
    centerMode: true,
    centerPadding: '495px',
    slidesToShow: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 1670,
        settings: {
          centerPadding: '450px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1580,
        settings: {
          centerPadding: '345px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1370,
        settings: {
          centerPadding: '245px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '200px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '110px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1,
        },
      },
    ],
  });

  // $('.cleanliness__item').hover(
  //   function() {
  //     $(this)
  //       .find('.cleanliness__item-text, .cleanliness__item-text--hover')
  //       .stop();
  //     $(this)
  //       .find('.cleanliness__item-text')
  //       .slideDown();
  //     $(this)
  //       .find('.cleanliness__item-text--hover')
  //       .slideUp();
  //   },
  //   function() {
  //     $(this)
  //       .find('.cleanliness__item-text, .cleanliness__item-text--hover')
  //       .stop();
  //     $(this)
  //       .find('.cleanliness__item-text')
  //       .slideUp();
  //     $(this)
  //       .find('.cleanliness__item-text--hover')
  //       .slideDown();
  //   }
  // );

  // Добавление 0
  // function pad(n) {
  //   if (n < 10) {
  //     return '0' + n;
  //   }
  //   return n;
  // }

  $('.operational-services__slider-items').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    draggable: true,
    adaptiveHeight: true,
    speed: 300,
    fade: true,
    rows: 'window',
    cssEase: 'linear',
    prevArrow: $('.operational-services__slider-prev'),
    nextArrow: $('.operational-services__slider-next'),
  });
  let slickCount = $('.operational-services__slider-items');
  $('.operational-services__slider-count').html(
    slickCount.slick('getSlick').slideCount
  );

  $('.operational-services__slider-items').on('afterChange', function(
    event,
    slick,
    currentSlide
  ) {
    $('#operational-services__slider--count').text(currentSlide + 1);
  });

  $('.cleaning-prices__items').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    arrows: false,
    speed: 600,
    responsive: [
      {
        breakpoint: 90000,
        settings: 'unslick',
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $(window).on('resize orientationchange', function() {
    let mySlider = $('.cleaning-prices__items');

    if (!mySlider.hasClass('slick-initialized')) {
      mySlider.slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        arrows: false,
        speed: 600,
        responsive: [
          {
            breakpoint: 90000,
            settings: 'unslick',
          },
          {
            breakpoint: 767,
            settings: 'slick',
          },
          {
            breakpoint: 575,
            settings: 'slick',
          },
        ],
      });
    }
  });

  $('.cleaning-prices__item')
    .mouseover(function() {
      $('.cleaning-prices__item').mousemove(function(e) {
        var x = e.pageX - $(this).offset().left,
          y = e.pageY - $(this).offset().top;

        var px = x / $(this).width(),
          py = y / $(this).height();

        var xx = -15 + 30 * px,
          yy = 15 - 30 * py;

        TweenMax.to($(this), 0.5, {
          rotationY: xx,
          rotationX: yy,
          rotationZ: 0,
          transformPerspective: 1000,
          ease: Quad.easeOut,
        });
      });
    })
    .mouseout(function() {
      $(this).unbind('mousemove');
      TweenMax.to($(this), 0.5, {
        rotationY: 0,
        rotationX: 0,
        rotationZ: 0,
        transformPerspective: 1000,
        ease: Quad.easeOut,
      });
    });

  // 3D card
  $(window).on('resize orientationchange', function() {
    $('.cleaning-prices__item')
      .mouseover(function() {
        $('.cleaning-prices__item').mousemove(function(e) {
          var x = e.pageX - $(this).offset().left,
            y = e.pageY - $(this).offset().top;

          var px = x / $(this).width(),
            py = y / $(this).height();

          var xx = -15 + 30 * px,
            yy = 15 - 30 * py;

          TweenMax.to($(this), 0.5, {
            rotationY: xx,
            rotationX: yy,
            rotationZ: 0,
            transformPerspective: 1000,
            ease: Quad.easeOut,
          });
        });
      })
      .mouseout(function() {
        $(this).unbind('mousemove');
        TweenMax.to($(this), 0.5, {
          rotationY: 0,
          rotationX: 0,
          rotationZ: 0,
          transformPerspective: 1000,
          ease: Quad.easeOut,
        });
      });
  });

  // $('.cleaning-cost__items').slick({
  //   infinite: true,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   dots: false,
  //   autoplay: true,
  //   arrows: false,
  //   speed: 800,
  //   responsive: [
  //     {
  //       breakpoint: 16000,
  //       settings: 'unslick',
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(window).on('resize orientationchange', function() {
  //   let mySlider = $('.cleaning-cost__items');

  //   if (!mySlider.hasClass('slick-initialized')) {
  //     mySlider.slick({
  //       infinite: true,
  //       slidesToShow: 4,
  //       slidesToScroll: 1,
  //       dots: false,
  //       autoplay: true,
  //       arrows: false,
  //       speed: 800,
  //       responsive: [
  //         {
  //           breakpoint: 16000,
  //           settings: 'unslick',
  //         },
  //         {
  //           breakpoint: 767,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 575,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //           },
  //         },
  //       ],
  //     });
  //   }
  // });

  // $('.feedback__items').slick({
  //   infinite: false,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   dots: false,
  //   autoplay: false,
  //   arrows: true,
  //   draggable: true,
  //   adaptiveHeight: true,
  //   speed: 600,
  //   rows: 'window',
  //   prevArrow: $('.feedback__button--prev'),
  //   nextArrow: $('.feedback__button--next'),
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(window).on('resize orientationchange', function() {
  //   let mySlider = $('.feedback__items');

  //   if (!mySlider.hasClass('slick-initialized')) {
  //     mySlider.slick({
  //       infinite: false,
  //       slidesToShow: 3,
  //       slidesToScroll: 1,
  //       dots: false,
  //       autoplay: false,
  //       arrows: true,
  //       draggable: true,
  //       speed: 600,
  //       rows: 'window',
  //       prevArrow: $('.feedback__button--prev'),
  //       nextArrow: $('.feedback__button--next'),
  //       responsive: [
  //         {
  //           breakpoint: 1199,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 992,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 767,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 575,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //           },
  //         },
  //       ],
  //     });
  //   }
  // });

  // $('.cleaning-list__items').slick({
  //   infinite: true,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   dots: false,
  //   autoplay: false,
  //   arrows: false,
  //   speed: 800,
  //   rows: 'window',
  //   responsive: [
  //     {
  //       breakpoint: 16000,
  //       settings: 'unslick',
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(window).on('resize orientationchange', function() {
  //   let mySlider = $('.cleaning-list__items');

  //   if (!mySlider.hasClass('slick-initialized')) {
  //     mySlider.slick({
  //       infinite: true,
  //       slidesToShow: 4,
  //       slidesToScroll: 1,
  //       dots: false,
  //       autoplay: false,
  //       arrows: false,
  //       speed: 800,
  //       adaptiveHeight: true,
  //       rows: 'window',
  //       responsive: [
  //         {
  //           breakpoint: 16000,
  //           settings: 'unslick',
  //         },
  //         {
  //           breakpoint: 992,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 767,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 575,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //           },
  //         },
  //       ],
  //     });
  //   }
  // });

  // $(document).on('click', '.submit-application__select-changer', function(e) {
  //   var direction = $(this).data('direction');
  //   var $select = $('#submit-application__select');
  //   var $currentOption = $select.find(':selected');
  //   var $nextOption =
  //     direction === 'down' ? $currentOption.next() : $currentOption.prev();
  //   if ($nextOption.length) {
  //     $select.val($nextOption.attr('value'));
  //   }
  // });

  // loadMore статьи
  let list = $('.articles-block__items .articles-block__item');
  let numToShow = 6;
  let button = $('#loadMore');
  let numInList = list.length;
  list.hide();
  if (numInList > numToShow) {
    button.show();
  }
  list.slice(0, numToShow).show();

  button.click(function() {
    let showing = list.filter(':visible').length;
    list.slice(showing - 1, showing + numToShow).fadeIn('slow');
    let nowShowing = list.filter(':visible').length;
    if (nowShowing >= numInList) {
      button.text('Больше нет').addClass('noLoadMore--js');
    }
  });
  // // loadMore статьи
  // let list2 = $('.articles-block__items .articles-block__item');
  // let numToShow2 = 6;
  // let button2 = $('#loadMore-2');
  // let numInList2 = list2.length;
  // list2.hide();
  // if (numInList2 > numToShow2) {
  //   button2.show();
  // }
  // list2.slice(0, numToShow2).show();

  // button2.click(function() {
  //   let showing2 = list2.filter(':visible').length;
  //   list2.slice(showing2 - 1, showing2 + numToShow2).fadeIn('slow');
  //   let nowShowing2 = list2.filter(':visible').length;
  //   if (nowShowing2 >= numInList2) {
  //     button2.text('Больше нет').addClass('noLoadMore--js');
  //   }
  // });
  // // loadMore faq
  // let list3 = $('.main-faq__wrap .card');
  // let numToShow3 = 4;
  // let button3 = $('#loadMore-3');
  // let numInList3 = list3.length;
  // list3.hide();
  // if (numInList3 > numToShow3) {
  //   button3.show();
  // }
  // list3.slice(0, numToShow3).show();

  // button3.click(function() {
  //   let showing3 = list3.filter(':visible').length;
  //   list3.slice(showing3 - 1, showing3 + numToShow3).fadeIn('slow');
  //   let nowShowing3 = list3.filter(':visible').length;
  //   if (nowShowing3 >= numInList3) {
  //     button3.hide();
  //   }
  // });

  // Фиксированная шапка
  // $('.navbar-collapse a').on('click', function () {
  //   $('.main-nav__items').removeClass('showMenu')
  //   $('.menu-toggle').removeClass('closeMenu')
  //   $('.navbar-collapse').collapse('hide')
  //   $('.navbar-toggler')
  //     .removeClass('active')
  //     .addClass('collapsed')
  //   $('.main-nav').toggleClass('opacity .main-nav--color fixed-top--mod-js')
  //   $('.phone__number').toggleClass('phone__number--color-js')
  //   $('.mode-operation').toggleClass('mode-operation--color-js')
  //   $('.btn-outline-primary').toggleClass('btn-outline-primary--color-js')
  //   $('.page').toggleClass('page-scroll')
  // })

  // $(() => {
  //   // Функция прокрутки
  //   $(window).scroll(() => {
  //     var windowTop = $(window).scrollTop()
  //     windowTop > 78 ? $('.fixed-top').addClass('fixed-top--mod') : $('.fixed-top').removeClass('fixed-top--mod')
  //     windowTop > 78 ? $('.phone__number').addClass('phone__number--color') : $('.phone__number').removeClass('phone__number--color')
  //     windowTop > 78 ? $('.mode-operation').addClass('mode-operation--color') : $('.mode-operation').removeClass('mode-operation--color')
  //     windowTop > 78 ? $('.btn-outline-primary').addClass('btn-outline-primary--color') : $('.btn-outline-primary').removeClass('btn-outline-primary--color')
  //   })
  // })

  // $('a[href*="#link-"]').on('click', function (e) {
  //   $('html,body').animate({
  //     scrollTop: $($(this).attr('href')).offset().top - 75
  //   }, 600)
  //   e.preventDefault()
  // })

  // Маска телефона
  // $('#phone').mask('+7 (999) 999-99-99')
  // Формы
  // $('form').submit(function () {
  //   var formID = $(this).attr('id') // Получение ID формы
  //   var formNm = $('#' + formID)
  //   $.ajax({
  //     type: 'POST',
  //     url: '/wp-content/themes/cleano/inc/mail.php', // Обработчик формы отправки
  //     data: formNm.serialize(),
  //     success: function(data) {
  //       // Вывод текста результата отправки в текущей форме
  //       $(formNm).html(data)
  //       setTimeout(function() {
  //         $('#phoneModal').modal('hide')
  //       }, 3000)
  //     }
  //   })
  //   return false
  // })

  // Добавление 0
  // function pad(n) {
  //   if (n < 10)
  //     return '0' + n
  //   return n
  // }
  // Слайдер
  // $('.slider__items').slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   dots: true,
  //   draggable: true,
  //   // mobileFirst: true,
  //   adaptiveHeight: true,
  //   // autoplay: true,
  //   // autoplaySpeed: 2000,
  //   speed: 500,
  //   fade: true,
  //   cssEase: 'linear',
  //   prevArrow: $('.reviews__prev'),
  //   nextArrow: $('.reviews__next')
  // })
  // let slickCount = $('.reviews__items')
  // $('.reviews__count').html(pad(slickCount.slick('getSlick').slideCount))

  // $('.reviews__items').on('afterChange', function (event, slick, currentSlide) {
  //   $('#reviews-count').text(pad(currentSlide + 1))
  // })
});
// Конец jQuery
